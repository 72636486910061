<script setup lang="ts">
  import { onMounted, ref } from 'vue';

  const isOpen = ref<boolean>(false);

  const props = defineProps<{
    open?: boolean;
  }>();

  function toggle() {
    isOpen.value = !isOpen.value;
  }

  onMounted(() => {
    isOpen.value = props.open;
  });
</script>

<template>
  <div class="block__info" :class="isOpen ? 'open' : ''">
    <div @click="toggle">
      <slot name="trigger"></slot>
    </div>
    <slot v-if="isOpen"></slot>
  </div>
</template>
